import React from 'react';
import { Form, Input } from 'antd';
import styles from './index.module.scss';
import { CloseCircleFilled } from '@ant-design/icons'; // Example icon
import { useTranslations } from 'next-intl';

interface PasswordInputProps {
  className?: string; // Allow passing a custom className
  errorMessage?: string;
  submitClicked?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = ({ className, errorMessage, submitClicked }) => {
  const errorIcon = <CloseCircleFilled style={{ color: 'red', marginRight: '5px' }} />;
  const t = useTranslations();
  return (
    <Form.Item
      name="password"
      rules={[
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(t('auth.passwordRequired'));
            }

            if (value.length < 8 || value.length > 15) {
              return Promise.reject(t('auth.passwordLenghtRequired'));
            }

            if (!/(?=.*[a-z])(?=.*[0-9])/.test(value)) {
              return Promise.reject(t('auth.passwordPatternRequired'));
            }

            return Promise.resolve();
          },
        },
      ]}
      help={
        submitClicked && errorMessage ? (
          <span>
            {errorIcon}
            {errorMessage}
          </span>
        ) : undefined
      }
      validateStatus={submitClicked && errorMessage ? 'error' : undefined}
      style={{ textAlign: 'left' }}
      className={className}
    >
      <Input.Password
        placeholder={t('auth.passwordPlaceholder')}
        className={styles.passwordInput}
        style={{ paddingLeft: '23px' }}
      />
    </Form.Item>
  );
};

export default PasswordInput;
