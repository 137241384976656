import { GeeTestValidateResult } from '@/hooks/interfaceGeeTest';
import client from './httpClient';

const baseUrl = '/lecturer/api/v1/login';
/**
 * emailLogin
 * @returns
 */

type EmailLoginData = {
  email: string;
  password: string;
};

export const emailLogin = (emailLoginData: EmailLoginData | (EmailLoginData & GeeTestValidateResult)) =>
  client.post(`${baseUrl}/password-email`, emailLoginData);

/**
 * passwordLogin
 * @param data FormData
 * @param data.mobile
 * @param data.password
 * @returns
 */
type MobileLoginData = {
  mobile: string;
  password: string;
};

export const mobileLogin = (mobileLoginData: MobileLoginData | (MobileLoginData & GeeTestValidateResult)) =>
  client.post(`${baseUrl}/password`, mobileLoginData);

type MobileSmsLoginData = {
  mobile: string;
  mobile_code: string;
};

export const mobileSmsLogin = (data: MobileSmsLoginData) => client.post(`${baseUrl}/password`, data);

export const checkChallengeRequired = () => client.get(`${baseUrl}/challenge-required`);
