'use client';
import { Form, message, Button } from 'antd';
import React, { useState } from 'react';
import styles from './index.module.scss';
import EmailInput from '@/components/EmailInput';
import PhoneInput from '@/components/PhoneInput';
import PasswordInput from '@/components/PasswordInput';
import { login } from '@/api/index';
import { loginAction } from '@/lib/features/userSlice';
import { setToken } from '@/utils/index';
import { JsonObject } from '@/types';
import { GeeTestValidateResult } from '@/hooks/interfaceGeeTest';
import { useGeeTest } from '@/hooks/useGeeTest';
// import SocialMedia from '@/components/SocialMedia';
import { useRouter } from 'next/navigation';
import { useAppDispatch } from '@/lib/hooks';
import CustomLink from '@/components/Link';
import { useTranslations } from 'next-intl';

const Login: React.FC = () => {
  const t = useTranslations('auth');
  const [activeTab, setActiveTab] = useState<'email' | 'phone'>('email'); // 'email' or 'phone'
  // const [loginMethod, setLoginMethod] = useState<'sms' | 'password'>('password');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [countdown, setCountdown] = useState(0);
  // const [disableButton, setDisableButton] = useState(false);
  // let countdownInterval: NodeJS.Timeout | null = null;
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);

  const getTabStyles = (tab: string) => ({
    color: activeTab === tab ? '#1169f7' : '#272a2d',
    borderBottom: activeTab === tab ? '3px solid #1169f7' : 'none',
    fontWeight: activeTab === tab ? 'bold' : 'normal',
  });

  const handleTabClick = (tab: 'email' | 'phone') => {
    setActiveTab(tab);
    setErrorMessage(''); // Reset the error message when switching tabs
  };

  // Define the type for form values
  type FormValues = {
    email: string;
    password: string;
    phoneNumber: string;
    countryCode: string;
    // Add other form fields here
  };

  const handleLogin = async (values?: GeeTestValidateResult) => {
    try {
      const formData: FormValues = form.getFieldsValue(['email', 'password', 'countryCode', 'phoneNumber']);
      let res: JsonObject;
      if (activeTab === 'email') {
        res = await login.emailLogin({
          email: formData.email,
          password: formData.password,
          ...values,
        });
      } else {
        res = await login.mobileLogin({
          mobile: formData.countryCode + formData.phoneNumber,
          password: formData.password,
          ...values,
        });
      }
      const token = res.data.token;
      setToken(token);
      message.success(t('loginSuccess'));
      form.resetFields(); // Reset the form after successful login
      dispatch(loginAction());
      router.push('/dashboard');
    } catch (error) {
      const err = error as JsonObject;
      console.error('Login failed:', err.message);
      setErrorMessage(err.message || 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    //make sure form field validated
    try {
      setSubmitClicked(true);
      setLoading(true);
      //check whether need geetest
      const res = await login.checkChallengeRequired();

      if (res?.data) captcha?.showCaptcha();
      else handleLogin();
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error:', error);
      setLoading(false);
    }
  };
  const { captcha, state } = useGeeTest(
    {
      product: 'bind',
      protocol: 'https://',
    },
    //callback
    handleLogin,
    () => {
      setLoading(false);
    },
  );
  return (
    <div className={styles['login']}>
      <div className={styles['login__title']}>{t('tutorLogin')}</div>
      <div className={styles['login__tab']}>
        <button onClick={() => handleTabClick('email')} style={getTabStyles('email')}>
          {t('email')}
        </button>
        <button onClick={() => handleTabClick('phone')} style={getTabStyles('phone')}>
          {t('phone')}
        </button>
      </div>
      <Form form={form} onFinish={handleSubmit}>
        {activeTab === 'email' && (
          <>
            <EmailInput className={styles.emailInputContainer} />
            <PasswordInput
              className={styles.passwordInputContainer}
              errorMessage={errorMessage}
              submitClicked={submitClicked}
            />
          </>
        )}

        {activeTab === 'phone' && (
          <>
            <PhoneInput
              className={styles.phoneInputContainer}
              errorMessage={errorMessage}
              submitClicked={submitClicked}
            />
            <PasswordInput className={styles.passwordInputContainer} />
          </>
        )}

        <div className={`${styles['form__button--link']} ${styles['form__button--forgotPassword']}`}>
          <CustomLink href="/forgot-password">{t('forgotPassword')}</CustomLink>
        </div>

        <div className={styles['form__buttonWrapper']}>
          <Button type="primary" htmlType="submit" loading={loading} className={styles['form__button']}>
            {t('loginNow')}{' '}
          </Button>
        </div>
      </Form>
      {/* <SocialMedia /> */}
    </div>
  );
};

export default Login;
