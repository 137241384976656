import client from './httpClient';

const baseUrl = '/lecturer/api/v1/help-articles';

export const getManualList = () => {
  return client.get(`${baseUrl}/list`, {});
};

export const getSpecificManual = (slug: string) => {
  return client.get(`${baseUrl}/${slug}`);
};

export const searchManual = (params: { search: string; page: number }) => {
  return client.get(`${baseUrl}`, params);
};
