import React from 'react';
import { Form, Input } from 'antd';
import styles from './index.module.scss';
import type { Rule } from 'antd/es/form';
import { WarningFilled } from '@ant-design/icons'; // Example icon
import { useTranslations } from 'next-intl';

interface EmailInputProps {
  className?: string; // Allow passing a custom className
  rules?: Rule[];
  errorMessage?: string;
  submitClicked?: boolean;
  placeholder?: string;
}

const EmailInput: React.FC<EmailInputProps> = ({ className, rules, errorMessage, submitClicked, placeholder }) => {
  const errorIcon = <WarningFilled style={{ color: 'red', marginRight: '5px' }} />;
  const t = useTranslations();
  let formRules: Rule[] = [
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(t('auth.emailRequired'));
        }

        if (!/^[a-zA-Z0-9._@-]+$/.test(value)) {
          return Promise.reject(t('auth.emailSpecailCharecter'));
        }

        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(value)) {
          return Promise.reject(t('auth.emailNotComplete'));
        }

        return Promise.resolve();
      },
    },
  ];

  // Regular expression for basic email validation
  // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // if (!emailRegex.test(value)) {
  //   return Promise.reject('邮箱地址不完整');
  // }

  if (rules) formRules = [...formRules, ...rules];
  return (
    <Form.Item
      name="email"
      rules={formRules}
      help={
        submitClicked && errorMessage ? (
          <span>
            {errorIcon}
            {errorMessage}
          </span>
        ) : undefined
      }
      validateStatus={submitClicked && errorMessage ? 'error' : undefined}
      style={{ textAlign: 'left' }}
      className={className} // Apply the passed className
    >
      <Input
        style={{ borderRadius: 100 }}
        placeholder={placeholder || t('auth.emailRequired')}
        className={styles.emailInput}
      />
    </Form.Item>
  );
};

export default EmailInput;
