import React from 'react';
import { Form, Input, Select } from 'antd';
import styles from './index.module.scss';
import { WarningFilled } from '@ant-design/icons'; // Example icon
import { useTranslations } from 'next-intl';

interface PhoneInputProps {
  className?: string;
  name?: string;
  currentPhoneNumber?: string;
  errorMessage?: string; // Add this line
  submitClicked?: boolean;
  placeholder?: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  className,
  currentPhoneNumber,
  errorMessage,
  submitClicked,
  placeholder,
}) => {
  const errorIcon = <WarningFilled style={{ color: 'red', marginRight: '5px' }} />;
  const t = useTranslations();
  return (
    <Form.Item
      name="phoneNumber"
      rules={[
        ({ getFieldValue }) => ({
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(t('auth.phoneRequired'));
            }
            if (!/^[0-9]+$/.test(value)) {
              return Promise.reject(t('auth.phoneDigitOnly'));
            }
            if (value.length > 15) {
              return Promise.reject(t('auth.phoneLength', { length: 15 }));
            }
            if (currentPhoneNumber) {
              if (getFieldValue('countryCode') + value === currentPhoneNumber)
                return Promise.reject(t('auth.phoneCannotBeSame'));
            }
            return Promise.resolve();
          },
        }),
      ]}
      help={
        submitClicked && errorMessage ? (
          <span>
            {errorIcon}
            {errorMessage}
          </span>
        ) : undefined
      }
      validateStatus={submitClicked && errorMessage ? 'error' : undefined}
      style={{ textAlign: 'left' }}
      className={className} // Apply the passed className
    >
      <div className={styles.phoneInput}>
        <Input
          addonBefore={
            <Form.Item name="countryCode" noStyle initialValue={'+86'}>
              <Select
                style={{ width: 70 }}
                // onChange={({ getFieldValue }) => {
                //   console.log('🚀 ~ file: index.tsx:46 ~ getFieldValue:', getFieldValue);
                // }}
              >
                <Select.Option value={'+60'}>+60</Select.Option>
                <Select.Option value={'+65'}>+65</Select.Option>
                <Select.Option value={'+82'}>+82</Select.Option>
                <Select.Option value={'+86'}>+86</Select.Option>
                <Select.Option value={'+852'}>+852</Select.Option>
                <Select.Option value={'+853'}>+853</Select.Option>
                <Select.Option value={'+886'}>+886</Select.Option>
              </Select>
            </Form.Item>
          }
          type="tel"
          placeholder={placeholder || t('auth.phoneRequired')}
        />
      </div>
    </Form.Item>
  );
};

export default PhoneInput;
