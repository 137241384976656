import client from './httpClient';
import { GeeTestValidateResult } from '@/hooks/interfaceGeeTest';

type GetCaptchaByEmail = GeeTestValidateResult & {
  email: string;
  scene: 'register' | 'password_reset' | 'email_bind' | 'email_reset';
  firstName?: string;
  lastName?: string;
  phone?: string;
  message?: string;
};

export const getCaptchaByEmail = (data: GetCaptchaByEmail) => client.post('/lecturer/api/v1/captcha/email', data);

type EmailResetPasswordData = {
  email: string;
  email_code: string;
  password: string;
};
export const emailResetPassword = (data: EmailResetPasswordData) =>
  client.post('/lecturer/api/v1/password/reset-email', data);

type GetCaptchaBySmsData = GeeTestValidateResult & {
  mobile: string;
  scene: 'login' | 'register' | 'password_reset' | 'mobile_bind' | 'email_reset' | 'mobile_reset';
};

export const getCaptchaBySms = (data: GetCaptchaBySmsData) => client.post('/lecturer/api/v1/captcha/sms', data);

type SmsResetPasswordData = {
  mobile: string;
  mobile_code: string;
  password: string;
};
export const smsResetPassword = (data: SmsResetPasswordData) => client.post('/lecturer/api/v1/password/reset', data);
